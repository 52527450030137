import React, { Component } from "react";
import "../Shopping.css";

class Footer extends Component {
  render() {
    return (
  
        <div className="centrar">
        <br/><br/>
          <div className="logo-nav-2-redes">
            <a href="https://www.facebook.com/LuisYdIntendente/">
              <img
                src="../../luisyd/gf.svg"
                className="redes-sociales-22"
                alt="facebook"
              />
            </a>

            <a href="https://www.instagram.com/luisyd_/">
              <img
                src="../../luisyd/ig.svg"
                className="redes-sociales-22"
                alt="instagram"
              />
            </a>

            <a href="https://twitter.com/luisyd_">
              <img
                src="../../luisyd/tw.svg"
                className="redes-sociales-22"
                alt=""
              />
            </a>
          </div>
        </div>
       
    );
  }
}

export default Footer;
